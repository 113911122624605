import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import WithPopCondition from 'HOCs/with-popup-condition';
import { Fragment } from 'react';

import { Typography } from '../typography/typography-component';
import { ModalProps } from './types';

function NewsletterModal({ open, onClose }: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto drop-shadow-2xl">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-24">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={() => onClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Typography variant="h4" className="font-bold leading-9">
                      Welcome to The SaaS Operator!
                    </Typography>
                    <Typography variant="paragraph" className="text-md mt-3">
                      Hey — EarlyNode Crew here. Thanks for subscribing.
                    </Typography>
                    <Typography variant="paragraph" className="text-md mt-3">
                      If you&apos;re here to learn from the best Operators in
                      SaaS, then you&apos;re in the right place.
                    </Typography>
                    <Typography variant="paragraph" className="text-md mt-3">
                      Before we get into those learnings, do us a quick favor…
                    </Typography>
                    <Typography variant="paragraph" className="text-md mt-3">
                      Check your inbox for our welcome email. Then move it to
                      your “primary” inbox to make sure you see the next
                      edition!
                    </Typography>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default WithPopCondition(NewsletterModal);

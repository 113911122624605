import { Size, Variant } from './types';

export const backgroundColors = {
  blue: 'bg-primary border-transparent text-blue-600 focus:ring-blue-500',
  orange:
    'bg-orange border-transparent text-white focus:ring-orange hover:bg-opacity-70',
  white:
    'bg-white border-transparent text-black focus:ring-white hover:bg-white hover:bg-opacity-70',
};

export const buttons = {
  defaultProps: {
    size: 'md' as Size,
    variant: 'filled' as Variant,
    fullwidth: false,
    color: 'blue' as keyof typeof backgroundColors,
    className: '',
    type: 'button',
    disabled: false,
  },
  styles: {
    variants: {
      filled: 'text-white border-none hover:bg-blue-700 shadow-sm',
      outlined:
        'bg-transparent border-2 hover:bg-blue-50 border-primary font-title hover:border-blue-600 shadow-sm',
      bare: 'bg-transparent hover:bg-blue-50 border-none',
    },
    fullwidth: 'w-full',
    sizes: {
      sm: 'px-2.5 py-1.5 text-xs',
      md: 'px-4 py-2 text-sm rounded-md',
      lg: 'px-6 py-3 text-base rounded-lg',
    },
    baseStyles:
      'flex flex-row items-center justify-center rounded border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed',
    colors: backgroundColors,
  },
};

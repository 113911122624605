/* eslint-disable jsx-a11y/anchor-has-content */
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { backgroundColors, buttons } from './button-helpers';
import { ButtonProps } from './types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      as,
      color,
      fullwidth,
      className,
      size,
      disabled,
      type,
      ...props
    },
    reference,
  ) => {
    const { defaultProps, styles } = buttons;
    variant = variant ?? defaultProps.variant;
    color = color ?? defaultProps.color;
    fullwidth = fullwidth ?? defaultProps.fullwidth;
    className = className ?? defaultProps.className;
    size = size ?? defaultProps.size;

    const { variants, sizes, fullwidth: fullWidthStyles, baseStyles } = styles;

    const bgColor = backgroundColors[color];

    const classes = twMerge(
      baseStyles,
      sizes[size],
      bgColor,
      variants[variant],
      fullwidth ? fullWidthStyles : '',
      className,
    );

    if (as === 'link') {
      return <p className={classes} {...props} />;
    }

    return (
      <button
        ref={reference}
        disabled={disabled}
        type={type}
        className={classes}
        {...props}
      />
    );
  },
);
